import axios from '@/apis/axios';
import { getAccessToken } from '@/lib/api';

/**
 * 重要事項等説明書一覧取得
 */
export const getImportantMatterList = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get('/agencies/important_matters/', {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 重要事項等説明書登録
 */
export const postImportantMatterMaster = async (
  file,
  importantMatterDetails
) => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('files[0]', file);
  form.append('importantMatterDetails', JSON.stringify(importantMatterDetails));

  const response = await axios
    .post(`/agencies/important_matters/`, form, {
      headers: {
        'content-type': 'multipart/form-data',
        'access-token': accessToken,
      },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 重要事項等説明書取得
 */
export const getImportantMatter = async importantMatterId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/important_matters/${importantMatterId}`, {
      responseType: 'blob',
      dataType: 'application/pdf',
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  if (!response) return;

  // typeの実態がapplication/octet-streamになっているためpdfに書き換える
  const blob = new Blob([response.data], { type: 'application/pdf' });

  return blob;
};

/**
 * 重要事項等説明書編集
 */
export const putImportantMatterMaster = async (
  file,
  importantMatterDetails,
  importantMatterId
) => {
  const accessToken = await getAccessToken();
  const form = new FormData();
  form.append('files[0]', file);
  form.append('importantMatterDetails', JSON.stringify(importantMatterDetails));

  const response = await axios
    .put(`/agencies/important_matters/${importantMatterId}`, form, {
      headers: {
        'content-type': 'multipart/form-data',
        'access-token': accessToken,
      },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 重要事項等説明書種目別適用開始日一覧取得
 */
export const getImportantMatterStartDateList = async insuranceTypeId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/important_matters/${insuranceTypeId}/start_dates`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};
