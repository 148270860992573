<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3" style="font-size:32px">
            {{ $t('title.agency.importantMatterList') }}
          </p>
        </v-col>
        <v-col style="padding-right:10px">
          <!-- 登録 -->
          <v-btn
            class="font-weight-black float-right mr-4"
            align="center"
            width="70vw"
            max-width="350px"
            color="next"
            style="font-size:20px"
            dark
            @click="onClickCreate()"
            >{{ $t('button.register') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row style="width:100%" class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <v-data-table
            calculate-widths
            class="elevation-1"
            :headers="headers"
            :items="records"
            :options.sync="options"
            :server-items-length="total"
            :loading="isLoading"
            :loading-text="$t('description.importantMatterReading')"
            :no-data-text="$t('error.importantMatterListNotFound')"
            :footer-props="{
              itemsPerPageText: '1ページあたりの行数',
              itemsPerPageOptions: itemsPerPageOptions,
            }"
          >
            <!-- 編集ボタン -->
            <template v-slot:[`item.edit`]="{ item }" class="pa-0">
              <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                  <v-btn color="next" fab x-small dark v-on="on">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                  <v-list-item color="error" @click="showImportantMatter(item)">
                    <v-icon>mdi-eye-outline</v-icon>
                    <v-list-item-action class="mx-0">
                      {{ $t('button.preview') }}
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-list class="ma-0 pa-0">
                  <v-list-item color="error" @click="onClickEdit(item)">
                    <v-icon>edit</v-icon>
                    <v-list-item-action class="mx-0">
                      {{ $t('button.edit') }}
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- 登録ダイアログ -->
      <FormsDialog
        :showDialog="showCreateFormsDialog"
        :title="$t('label.createImportantMatter')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.register')"
        :onClickNegativeButton="cancelCreate"
        :onClickPositiveButton="createImportantMatter"
        :targets="createTargets"
      />
      <!-- 編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditFormsDialog"
        :title="$t('label.editImportantMatter')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEdit"
        :onClickPositiveButton="updateImportantMatter"
        :targets="editTargets"
      />
      <ErrorDialog ref="errorDialog"></ErrorDialog>
      <CompletedDialog ref="completedDialog"></CompletedDialog>
    </v-layout>
  </v-container>
</template>

<script>
import {
  MaxLength,
  ImportantMatterListTableHeader,
  ImportantMatterListDisplayDefault,
  uploadLimitFileSize,
} from '@/lib/const';
import moment from 'moment';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import { getInsuranceTypeList } from '@/apis/agency/insuranceTypes';
import {
  getImportantMatterList,
  postImportantMatterMaster,
  putImportantMatterMaster,
  getImportantMatterStartDateList,
} from '@/apis/agency/importantMatters';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { mapActions } from 'vuex';

export default {
  name: 'ImportantMatterList',
  components: { FormsDialog, CompletedDialog, ErrorDialog },

  data() {
    return {
      headers: ImportantMatterListTableHeader,
      records: [],

      // 重要事項等説明書一覧取得オプション
      options: {
        page: ImportantMatterListDisplayDefault.page,
        itemsPerPage: ImportantMatterListDisplayDefault.itemsPerPage,
        sortBy: [],
        sortDesc: [],
      },
      defualtTargets: [
        {
          value: null,
          targetName: 'file',
          type: 'file',
          accept: ".pdf",
          acceptText: this.$t('label.uploadableFileExtensionsPdf'),
          limitFileSizeText: this.$t('label.uploadLimitFileSize'),
          label: null,
          text: null,
          rule: `selectRequired|checkExt:pdf|maxSize:${uploadLimitFileSize}`,
          key: 'file',
        },
        {
          value: null,
          targetName: 'formId',
          type: 'text',
          label: this.$t('header.importantMatterListTable.formId'),
          text: null,
          rule: `required|max:${MaxLength.FormId}|pattern:formId`,
          key: 'formId',
        },
        {
          value: null,
          targetName: 'name',
          type: 'text',
          label: this.$t('header.importantMatterListTable.name'),
          text: null,
          rule: `required|max:${MaxLength.ImportantMatterName}`,
          key: 'name',
        },
        {
          value: null,
          targetName: 'insuranceTypeId',
          type: 'select',
          listName: 'insuranceTypeList',
          label: this.$t('header.importantMatterListTable.insuranceName'),
          text: null,
          rule: 'selectRequired',
          key: 'insuranceTypeId',
          // function: this.selectInsuranceType,
        },
        {
          value: null,
          targetName: 'startDate',
          type: 'date',
          label: this.$t('header.importantMatterListTable.startDate'),
          text: null,
          rule: 'required',
          key: 'startDate',
        },
      ],
      createTargets: [],
      editTargets: [],
      editId: null,
      editPdfFileRule: `checkExt:pdf|maxSize:${uploadLimitFileSize}`,

      // 1ページあたりの表示件数の選択肢
      itemsPerPageOptions:
        ImportantMatterListDisplayDefault.itemsPerPageOptions,

      // 一覧取得件数
      total: 0,

      // ローディング中であるかどうか
      isLoading: true,

      // 種目リスト
      insuranceTypeList: [],

      // 帳票IDリスト
      formIdList: [],

      // 自身の帳票ID
      myFormId: null,

      showCreateFormsDialog: false,
      showEditFormsDialog: false,
    };
  },
  async mounted() {
    await this.getRecord();

    const insuranceTypeList = await getInsuranceTypeList();
    this.$set(
      this,
      'insuranceTypeList',
      insuranceTypeList.map(insuranceType => {
        return {
          text: insuranceType.name,
          value: insuranceType.id,
        };
      })
    );

    this.$set(this, 'isLoading', false);
  },
  methods: {
    // ...mapActions('validation', ['setStartDates']),
    ...mapActions('ui', ['setLoading']),
    onClickCreate() {
      this.$set(this, 'editId', null);
      // this.setStartDates([]);

      this.$set(
        this,
        'createTargets',
        this.defualtTargets.map(createTarget => {
          createTarget.value = null;
          // リストの取得
          if (createTarget.listName)
            createTarget.list = this[createTarget.listName];

          return createTarget;
        })
      );
      this.$set(this, 'showCreateFormsDialog', true);
    },
    cancelCreate() {
      this.$set(this, 'showCreateFormsDialog', false);
    },
    async createImportantMatter(item) {
      const file = item.file;
      delete item['file'];

      // 帳票IDの重複チェック
      if (this.formIdList.includes(item.formId)) {
        this.$refs.errorDialog.open(
          this.$t('title.agency.registerFormIdError'),
          this.$t('error.formId', { formId: item.formId })
        );
        return;
      }

      const result = await postImportantMatterMaster(file, item).catch(err => {
        console.log(err);
      });

      if (result) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.createResult'),
          this.$t('success.created')
        );
        await this.getRecord();
      } else {
        this.$refs.errorDialog.open(
          this.$t('title.agency.createResult'),
          this.$t('error.createFailed')
        );
      }
      this.$set(this, 'showCreateFormsDialog', false);
    },
    onClickEdit(item) {
      this.$set(this, 'editId', item.id);
      this.$set(this, 'myFormId', item.formId);
      // this.getStartDate(item.insuranceTypeId);

      this.editTargets = this.defualtTargets.map(editTarget => {
        if (editTarget.type === 'file') {
          editTarget.rule = this.editPdfFileRule; // requiredの解除
          editTarget.value = null; // ファイルにvalueが存在しないため
          return editTarget;
        }

        editTarget.value = item[editTarget.targetName];
        // リストの取得
        if (editTarget.listName) editTarget.list = this[editTarget.listName];
        if (editTarget.type === 'date')
          editTarget.value = editTarget.value.replace(/\//g, '-');

        return editTarget;
      });

      this.$set(this, 'showEditFormsDialog', true);
    },
    cancelEdit() {
      this.$set(this, 'showEditFormsDialog', false);
    },
    async updateImportantMatter(item) {
      const file = item.file ? item.file : null;
      delete item['file'];

      // 帳票IDの重複チェック
      if (this.formIdList.includes(item.formId) && item.formId !== this.myFormId) {
        this.$refs.errorDialog.open(
          this.$t('title.agency.updateFormIdError'),
          this.$t('error.formId', { formId: item.formId })
        );
        return;
      }

      const result = await putImportantMatterMaster(
        file,
        item,
        this.editId
      ).catch(err => {
        console.log(err);
      });

      if (result) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t('success.updated')
        );
        await this.getRecord();
      } else {
        this.$refs.errorDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t('error.updateFailed')
        );
      }
      this.$set(this, 'showEditFormsDialog', false);
    },
    showImportantMatter(item) {
      this.$router.push({
        name: 'ImportantMatterPreview',
        params: {
          importantMatterId: item.id,
        },
      });
    },
    async getRecord() {
      const importantMatterList = await getImportantMatterList();
      let formIdList = [];
      this.$set(
        this,
        'records',
        importantMatterList.map(importantMatter => {
          formIdList.push(importantMatter.formId);
          return {
            id: importantMatter.id,
            formId: importantMatter.formId,
            name: importantMatter.name,
            insuranceTypeId: importantMatter.insuranceTypeId,
            insuranceName: importantMatter.insuranceType.name,
            startDate: moment(importantMatter.startDate).format('YYYY/MM/DD'),
            latestUpdate: moment(importantMatter.updatedAt).format(
              'YYYY/MM/DD'
            ),
          };
        })
      );
      this.$set(this, 'formIdList', formIdList);
      this.$set(this, 'total', importantMatterList.count);
    },
    // NOTE: UAT128対応、重複条件改善予定
    // selectInsuranceType(item) {
    //   this.getStartDate(item.value);
    // },
    // async getStartDate(insuranceTypeId) {
    //   this.setLoading(true);

    //   const startDateList = await getImportantMatterStartDateList(
    //     insuranceTypeId
    //   );
    //   let validateStartDates = [];
    //   for (let i = 0; i < startDateList.length; i++) {
    //     if (this.editId !== startDateList[i].id)
    //       validateStartDates.push(
    //         moment(startDateList[i].startDate).format('YYYY-MM-DD')
    //       );
    //   }

    //   this.setStartDates(validateStartDates);
    //   this.setLoading(false);
    // },
  },
  computed: {},
};
</script>
<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v-data-table td {
  word-break: break-all;
}
/* 1ページあたりの行数を IE 11 でも表示させる */
.v-data-footer__select .v-select {
  width: 54px !important;
}
.v-data-table {
  overflow: hidden;
}
</style>
