var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", wrap: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "p",
                  {
                    staticClass: "font-weight-black pl-3",
                    staticStyle: { "font-size": "32px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("title.agency.importantMatterList")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c(
                "v-col",
                { staticStyle: { "padding-right": "10px" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black float-right mr-4",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        align: "center",
                        width: "70vw",
                        "max-width": "350px",
                        color: "next",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.onClickCreate()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.register")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0", staticStyle: { width: "100%" } },
            [
              _c(
                "v-col",
                { staticClass: "ma-0 pa-0" },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      "calculate-widths": "",
                      headers: _vm.headers,
                      items: _vm.records,
                      options: _vm.options,
                      "server-items-length": _vm.total,
                      loading: _vm.isLoading,
                      "loading-text": _vm.$t(
                        "description.importantMatterReading"
                      ),
                      "no-data-text": _vm.$t(
                        "error.importantMatterListNotFound"
                      ),
                      "footer-props": {
                        itemsPerPageText: "1ページあたりの行数",
                        itemsPerPageOptions: _vm.itemsPerPageOptions
                      }
                    },
                    on: {
                      "update:options": function($event) {
                        _vm.options = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.edit",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "", left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    color: "next",
                                                    fab: "",
                                                    "x-small": "",
                                                    dark: ""
                                                  }
                                                },
                                                on
                                              ),
                                              [_c("v-icon", [_vm._v("edit")])],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    { staticClass: "ma-0 pa-0" },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { color: "error" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showImportantMatter(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-eye-outline")
                                          ]),
                                          _c(
                                            "v-list-item-action",
                                            { staticClass: "mx-0" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("button.preview")
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list",
                                    { staticClass: "ma-0 pa-0" },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { color: "error" },
                                          on: {
                                            click: function($event) {
                                              return _vm.onClickEdit(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [_vm._v("edit")]),
                                          _c(
                                            "v-list-item-action",
                                            { staticClass: "mx-0" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("button.edit")
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showCreateFormsDialog,
              title: _vm.$t("label.createImportantMatter"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.register"),
              onClickNegativeButton: _vm.cancelCreate,
              onClickPositiveButton: _vm.createImportantMatter,
              targets: _vm.createTargets
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showEditFormsDialog,
              title: _vm.$t("label.editImportantMatter"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.cancelEdit,
              onClickPositiveButton: _vm.updateImportantMatter,
              targets: _vm.editTargets
            }
          }),
          _c("ErrorDialog", { ref: "errorDialog" }),
          _c("CompletedDialog", { ref: "completedDialog" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }